<template>
    <div class="details card center">
        <img
            class="top-left-ribbon"
            src="./assets/top_left_corner_ribbon.png"
        >
        <h1 class="gold header-text-cursive detail-header">
            Andrew's 2020
        </h1>
        <div class="info-section">
            <h2>I moved</h2>
            <p>
                In August of 2020, I decided I wanted my own place and started renting a new townhouse in Waukee! It's
                a great little complex right behind the YMCA - it just so happens to be around the corner from
                Josh and Teresa! Email me at <a href="mailto:andrew@gorman.rocks">andrew@gorman.rocks</a> if you need
                the
                new address!
            </p>
            <img src="./assets/205.jpeg">
            <p>
                I'm super excited to have my own place with no roommates and limited shared walls! The neighbors are
                great so far and I'm really loving it here. As much as I love it, i'm hoping to save up enough in the
                next two years to get my own real house with a yard!
            </p>
        </div>
        <div class="info-section">
            <h2>I worked</h2>
            <p>
                2020 was an interesting year for work. As many of you know I started the year working for IMT Insurance
                as a Programmer Analyst. This past summer I got a title bump to ‘Full Stack Developer I’ and I expect to
                get a full promotion to ‘Full Stack Developer II’ this coming spring!
            </p>
            <p>
                It’s really strange to think that I’ve worked almost half of my career remote now, but it’s a blessing
                that I have a job that isn’t impacted much from working from home!
            </p>
            <p>
                On the note of working from home, I just tackled my first ever woodworking project! I missed my electric
                standing desk at IMT but couldn’t stomach the price of a new one so I made my own! (shoutout to dad for
                helping me cut the wood and answering all my dumb questions)
            </p>
            <img src="./assets/desk_wide.jpeg">
            <p>Walnut butchers block with electric motorized legs</p>
            <img src="./assets/desk_closeup.jpeg">
        </div>
        <div class="info-section">
            <h2>I got fit</h2>
            <p>
                2020 was also a year for self-improvement (I mean, what else am I going to do stuck at home all year?) I
                re-joined Farrells kickboxing and I actually won the summer 10-week challenge!
            </p>
            <img src="./assets/winning.jpeg">
            <p>I still have a ways to go to get where I want to be but this is a great start.</p>
        </div>
        <div class="info-section">
            <h2>I adopted Delta</h2>
            <p>Last, but CERTAINLY not least, I adopted Delta! He’s gotten so big so fast, it’s blowing my mind.</p>
            <img src="./assets/delta_kitten.jpeg">
            <p>He's grown so much (but still sleeps the same excessive amount)</p>
            <img src="./assets/delta_large.jpeg">
            <p>
                He’s super smart (a little too much for his own good occasionally) and is training super well! He knows
                how to sit, speak, and ‘go to his room’ already!
            </p>
            <video controls>
                <source
                    src="./assets/go_to_your_room.mp4"
                    type="video/mp4"
                >
            </video>
        </div>
        <div class="info-section">
            <h2>2021</h2>
            <p>
                In the next year, my goals are to finish up my <a href="https://www.andrewgorman.dev/">personal
                    website</a> and <a href="https://portfolio.andrewgorman.dev/">portfolio</a>
                and to start in on some fun personal projects, as well as to continue learning Spanish and French, and
                to earn some certifications in my field! I’m also considering learning guitar!
            </p>
        </div>
        <div class="info-section">
            <h2>I'd love to hear from you!</h2>
            <p>
                Thanks for reading through our Christmas card! I’d love to hear from you and hear how 2020 went for you!
                Drop me a text or email! When you do, be sure to update me with any change of email/number/address you
                might have!
            </p>
            <p>
                My new email(s) are <a href="mailto:andrew@gorman.rocks">andrew@gorman.rocks</a> (for personal things)
                and <a href="mailto:andrew@andrewgorman.dev">andrew@andrewgorman.dev</a> (for professional things)
            </p>
        </div>
        <div style="display: flex; justify-content: space-around;">
            <router-link
                :to="{name: 'christmas-cards.2020'}"
                class="christmas-button"
            >
                Back to home
            </router-link>
        </div>
        <div class="info-section">
            <h2>Bloopers</h2>
            <p>Getting that cover photo was a challenge, enjoy these two bloopers!</p>
            <img src="./assets/outtake_2.jpeg">
            <img src="./assets/outtake_1.jpeg">
        </div>
        <img
            class="bottom-right-ribbon"
            src="./assets/bottom_right_corner_ribbon.png"
        >
    </div>
</template>
<script>

    export default {
        name: 'Details',
        mounted() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    };
</script>
<style lang="sass">
    html, body
        .website-container
            .card
                color: black

                .detail-header
                    font-size: 3.5rem
                    text-align: center

</style>
